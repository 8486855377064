import { useEffect, useState } from 'react'

const domLoadedEvents: Array<keyof WindowEventMap | 'DOMContentLoaded'> = ['DOMContentLoaded', 'load']
const userInteractionEvents: Array<keyof WindowEventMap> = [
  'mousemove',
  'scroll',
  'keydown',
  'keypress',
  'keyup',
  'mouseenter',
]

export const useContentLoaded = () => {
  const loadedAndInteractive = typeof window !== 'undefined' && window.loadedAndInteractive === true
  const [domLoaded, setDomLoaded] = useState(loadedAndInteractive)
  const [userInteracted, setUserInteracted] = useState(loadedAndInteractive)
  useEffect(() => {
    if (!domLoaded && !loadedAndInteractive) {
      const domLoaderWatcher = () => setDomLoaded(true)
      domLoadedEvents.forEach((event) => (
        window.addEventListener(event, domLoaderWatcher, { capture: true, once: true })
      ))
      return () => domLoadedEvents.forEach((event) => (
        window.removeEventListener(event, domLoaderWatcher)
      ))
    }
    return () => {}
  }, [domLoaded, loadedAndInteractive])

  useEffect(() => {
    if (!userInteracted && !loadedAndInteractive) {
      const userInteractionWatcher = () => setUserInteracted(true)
      userInteractionEvents.forEach((event) => (
        window.addEventListener(event, userInteractionWatcher, { capture: true, once: true })
      ))
      try { // iframes covering the whole screen don't propagate user events
        const iframes = document.getElementsByTagName('iframe')
        userInteractionEvents.forEach((event) => {
          [...iframes].forEach((iframe) => {
            iframe.addEventListener(event, userInteractionWatcher, { capture: true, once: true })
          })
        })
      } catch {
        // Fail silently
      }
      return () => {
        userInteractionEvents.forEach((event) => (
          window.removeEventListener(event, userInteractionWatcher)
        ))
        try {
          const iframes = document.getElementsByTagName('iframe')
          userInteractionEvents.forEach((event) => {
            [...iframes].forEach((iframe) => {
              iframe.removeEventListener(event, userInteractionWatcher)
            })
          })
        } catch {
          // Fail silently
        }
      }
    }
    return () => {}
  }, [userInteracted, loadedAndInteractive])

  useEffect(() => {
    if (domLoaded && userInteracted && !window.loadedAndInteractive) {
      window.loadedAndInteractive = true
      window.dispatchEvent(new Event('loadedAndInteractive'))
    }
  }, [domLoaded, userInteracted])
  return (domLoaded && userInteracted) || loadedAndInteractive
}

export default useContentLoaded
