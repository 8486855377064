import React from 'react'
import { PLACES_KEY } from '@/services/Configuration'
import { useContentLoaded } from '@/hooks/useContentLoaded'
import Script from 'next/script'

const GoogleMaps: React.FC = () => {
  const contentLoaded = useContentLoaded()
  if (!contentLoaded) {
    return null
  }
  return (
    <Script
      strategy="afterInteractive"
      src={`https://maps.googleapis.com/maps/api/js?key=${PLACES_KEY}&libraries=places`}
    />
  )
}

export default GoogleMaps
